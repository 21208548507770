import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

class Pagination extends React.PureComponent {
  static propTypes = {
    previousUrl: PropTypes.string,
    nextUrl: PropTypes.string,
    count: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const { previousUrl, nextUrl, count, pageSize, onClick } = this.props;
    const prevParams = previousUrl ? queryString.parse(queryString.extract(previousUrl)) : {};
    const nextParams = nextUrl ? queryString.parse(queryString.extract(nextUrl)) : {};
    let pageParams = { page: 1 };
    if (prevParams.page) {
      pageParams.page = parseInt(prevParams.page) + 1;
    } else if (nextParams.page) {
      pageParams.page = parseInt(nextParams.page) - 1;
    }
    const pageCount = Math.floor(count / pageSize) + 1;
    const pages = [];
    for (let i = 0; i < pageCount; i++) {
      const pageNumber = i + 1;
      if (pageParams.page === pageNumber) {
        pages.push(
          <li key={`pagination-${pageNumber}`} className="active">
            <span>
              {pageNumber}
              <span className="sr-only">(current)</span>
            </span>
          </li>
        );
      } else {
        const pageFilter = { page: pageNumber };
        pages.push(
          <li key={`pagination-${pageNumber}`}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onClick(pageFilter);
              }}
            >
              {pageNumber}
            </a>
          </li>
        );
      }
    }

    return (
      <nav aria-label="Profile List Pagination">
        <ul className="pagination">
          {previousUrl ? (
            <li>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onClick(prevParams);
                }}
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
          ) : (
            <li className="disabled">
              <span>
                <span aria-hidden="true">&laquo;</span>
              </span>
            </li>
          )}
          {pages}
          {nextUrl ? (
            <li>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onClick(nextParams);
                }}
                aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          ) : (
            <li className="disabled">
              <span>
                <span aria-hidden="true">&raquo;</span>
              </span>
            </li>
          )}
        </ul>
      </nav>
    );
  }
}

export default Pagination;
