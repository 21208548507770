import React from 'react';
import PropTypes from 'prop-types';

const AttendanceLegend = ({ tableBodyStyles = {} }) => {
  return (
    <React.Fragment>
      <h3 className="bold center">Legend</h3>
      <table className="table table-bordered center">
        <thead>
          <tr className="active">
            <th className="center">Symbol</th>
            <th className="center">Meaning</th>
            <th className="center">Attendance</th>
          </tr>
        </thead>
        <tbody style={tableBodyStyles}>
          <tr>
            <td className="success">
              <i className="fa fa-check-circle" />
            </td>
            <td>Present</td>
            <td>Counted</td>
          </tr>
          <tr>
            <td className="warning">
              <i className="fa fa-clock-o" />
            </td>
            <td>Late</td>
            <td>Counted</td>
          </tr>
          <tr>
            <td className="danger">
              <i className="fa fa-minus-circle" />
            </td>
            <td>Absent</td>
            <td>Not Counted</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

AttendanceLegend.propTypes = {
  tableBodyStyles: PropTypes.object,
};

export default AttendanceLegend;
