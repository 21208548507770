import React from 'react';
import PropTypes from 'prop-types';

const StrandPanelHeading = (props) => {
  return (
    <div className={`panel-heading ${props.strand}`}>
      <div className="row">
        <div className="col-xs-12">
          <div className={`event-block--${props.strand}`}>
            <div className="panel-heading-flex">
              <div className="event-block__strand-icon" />
              <div className="event-block__strand-title panel-title panel-height">
                {props.title}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StrandPanelHeading.propTypes = {
  strand: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
};

export default StrandPanelHeading;
