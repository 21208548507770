import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => (
  <button className={props.className} onClick={props.onClick} disabled={props.disabled}>
    {props.icon && <i className={`icon-space-r fa ${props.icon}`} />}
    {props.text}
  </button>
);

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  className: 'btn btn-default',
  icon: '',
  text: 'Submit',
  disabled: false,
};

export default Button;
