import React, { Component } from 'react';
import Registration from './Registration';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { userPropTypes } from 'helpers/proptypes';
import { requestCurrentUser } from 'store/actions/accounts/user';
import { requestRegistrationsByYear } from 'store/actions/registrations/';
import { Loading, Alert, PageHeader } from 'components';
import { AcademicYearSelect } from 'components/shared';

export class RegistrationsArchive extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    user: userPropTypes,
    registrationsByYear: PropTypes.array,
    requestCurrentUser: PropTypes.func.isRequired,
    requestRegistrationsByYear: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const d = new Date();
    this.state = {
      academicYear: d.getFullYear() - 1 + '-' + d.getFullYear(),
    };
  }

  componentDidMount() {
    this.props.requestRegistrationsByYear(this.state.academicYear);
    this.props.requestCurrentUser();
  }

  handleAcademicYearChange = (selectedValue) => {
    this.setState(
      {
        academicYear: selectedValue,
      },
      () => this.props.requestRegistrationsByYear(this.state.academicYear)
    );
  };

  render() {
    if (this.props.hasErrored) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          Something went wrong. Could not retrieve user or registration data.
        </Alert>
      );
    }

    return (
      <div className="white-soft-box2 white-soft-box2--no-animate">
        <PageHeader.Basic
          title="Registrations Archive"
          actions={
            <React.Fragment>
              <AcademicYearSelect
                value={this.state.academicYear}
                onChange={this.handleAcademicYearChange}
                style={{ width: 200 }}
              />
              <Link
                to={window.URLS['accounts:registrations']()}
                className="btn btn-primary registrations-archive__reg-link"
              >
                <i className="fa fa-list-alt icon-space-r" />
                Registrations
              </Link>
            </React.Fragment>
          }
        />
        {this.props.isLoading ? (
          <Loading />
        ) : this.props.registrationsByYear.length ? (
          this.props.registrationsByYear.map((reg, index) => {
            return <Registration key={reg.id} reg={reg} user={this.props.user} />;
          })
        ) : (
          <div className="page-empty">
            <div className="page-empty__icon">
              <span className="fa fa-university" />
            </div>
            <div className="page-empty__title">Archive Empty</div>
            <div className="page-empty__subtitle mt1">
              <Link to={window.URLS['accounts:registrations']()} className="btn btn-default">
                Registrations
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ accountsUser, registrations }) => {
  return {
    user: accountsUser[accountsUser.current],
    registrationsByYear: registrations.registrationsByYear,
    isLoading: accountsUser.isLoading || registrations.registrationsByYearLoading,
    hasErrored: accountsUser.hasErrored || registrations.error,
  };
};

export default connect(
  mapStateToProps,
  { requestCurrentUser, requestRegistrationsByYear }
)(RegistrationsArchive);
