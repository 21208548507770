import React from 'react';
import PropTypes from 'prop-types';
import Select, { Option, OptGroup } from 'rc-select';
import 'rc-select/assets/index.css';

const dropdownMenuStyle = {
  maxHeight: 500,
  overflow: 'auto',
};

const EventSelection = (props) => {
  const events = props.events || [];
  const optGroups = events.reduce((acc, event) => {
    const yearSeason = `${event.academic_year} ${event.academic_season}`;
    if (!acc[yearSeason]) {
      acc[yearSeason] = [];
    }
    acc[yearSeason] = [...acc[yearSeason], [event.id, event.title]];
    return acc;
  }, {});
  const children = Object.keys(optGroups).map((yearSeason) => (
    <OptGroup label={<span className="bold">{yearSeason}</span>} key={yearSeason}>
      {optGroups[yearSeason].map((event) => (
        <Option key={event[0]} lowercaseName={event[1].toLowerCase()}>
          {event[1]}
        </Option>
      ))}
    </OptGroup>
  ));
  return (
    <div className="form-group">
      <label htmlFor="" className="control-label">
        {props.label}
      </label>
      <div>
        <Select
          value={props.event}
          dropdownMenuStyle={dropdownMenuStyle}
          dropdownStyle={props.dropdownStyle}
          style={{ width: '100%' }}
          optionFilterProp="lowercaseName"
          optionLabelProp="children"
          placeholder="Select event..."
          notFoundContent="No events found"
          onChange={props.onChange}
          onBlur={props.onBlur}
          name={props.name}
        >
          {children}
        </Select>
      </div>
      <p className="help-block">
        {props.help || 'If you do not know the event, you may leave this blank.'}
      </p>
    </div>
  );
};

EventSelection.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  event: PropTypes.string,
  events: PropTypes.array,
  label: PropTypes.string.isRequired,
  help: PropTypes.string,
  dropdownStyle: PropTypes.object,
};

export default EventSelection;
