import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { EventDictionary } from 'helpers/dictionaries';
import { eventExplorePropTypes, userPropTypes } from 'helpers/proptypes';

const { REG_STATUS } = EventDictionary;
const { STEP_FORWARD_LEADERSHIP } = EventDictionary.EVENT_TYPES;

const UserEventActions = ({
  event,
  user,
  registrationOpen,
  addDropOpen,
  onAddCartClick,
  onRemoveCartClick,
  onRegistrationRequestClick,
  showEventLink,
}) => {
  // NOTE: They could have been denied, or another status
  const didRegister =
    !!event.registration_status && event.registration_status !== REG_STATUS.IN_CART;
  const isInCart = user.shoppingcart.events.includes(event.pk);
  const isEnded = event.last && moment(event.last).isBefore(moment());

  if (event.canceled) {
    return (
      <>
        {(event.registration_status === REG_STATUS.FACILITATOR ||
          user.isAdmin ||
          user.isManager) && (
          <Link
            to={window.URLS['pd:event_registration_list'](event.pk)}
            className="btn btn-default btn-sm icon-space-l"
          >
            <i className="fa fa-users icon-space-r" />
            Registration List
          </Link>
        )}
      </>
    );
  }
  return (
    <>
      {user.isAdmin && (
        <div className="btn-group dropdown">
          <button
            type="button"
            className="multiselect dropdown-toggle btn btn-default btn-sm"
            data-toggle="dropdown"
            title="None selected"
            aria-expanded="false"
          >
            <span className="fa fa-pencil icon-space-r" />
            Edit <b className="caret" />
          </button>
          <ul className="dropdown-menu">
            <li>
              <a href={window.URLS['pd:event_proposal_update'](event.pk)}>
                <span className="fa fa-pencil icon-space-r" />
                Edit Proposal
              </a>
            </li>
            <li>
              <a href={window.URLS['pd:event_update'](event.pk)}>
                <span className="fa fa-pencil icon-space-r" />
                Edit Event
              </a>
            </li>
            <li role="separator" className="divider" />
            <li>
              <a href={window.URLS['pd:event_delete'](event.pk)}>
                <span className="fa fa-trash icon-space-r" />
                Delete Event
              </a>
            </li>
          </ul>
        </div>
      )}
      {showEventLink && (
        <Link
          to={window.URLS['pd:event_detail'](event.pk)}
          className="btn btn-default btn-sm icon-space-l"
        >
          <i className="fa fa-calendar-o icon-space-r" />
          View Detail
        </Link>
      )}
      {didRegister && event.can_drop && (
        <a
          href={window.URLS['pd:registration_drop'](event.pk)}
          className="btn btn-default btn-sm icon-space-l"
        >
          <i className="fa fa-trash icon-space-r" />
          Drop
        </a>
      )}
      {didRegister && event.can_withdraw && (
        <a
          href={window.URLS['pd:registration_withdraw'](event.pk)}
          className="btn btn-default btn-sm icon-space-l"
        >
          <i className="fa fa-times icon-space-r" />
          Withdraw
        </a>
      )}
      {event.registration_status === REG_STATUS.APPROVED && (
        <React.Fragment>
          <a
            href={window.URLS['pd:person_attendance_log_detail'](event.pk, user.profile.slug)}
            className="btn btn-default btn-sm icon-space-l"
          >
            <i className="fa fa-list-alt icon-space-r" />
            My Attendance
          </a>
          {isEnded && (
            <React.Fragment>
              <a
                href={window.URLS['pd:sign_activity_report'](event.pk, user.profile.slug)}
                className="btn btn-default btn-sm icon-space-l"
              >
                <i className="fa fa-pencil-square-o icon-space-r" />
                Sign Activity Report
              </a>
              <a
                href={window.URLS['pd:certificate_export'](event.pk, user.profile.slug)}
                className="btn btn-default btn-sm icon-space-l"
              >
                <i className="fa fa-file-pdf-o icon-space-r" />
                View Certificate
              </a>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {(event.registration_status === REG_STATUS.FACILITATOR || user.isAdmin) && (
        <a
          href={window.URLS['pd:event_proposal_detail'](event.pk)}
          className="btn btn-default btn-sm icon-space-l"
        >
          <i className="fa fa-file-text-o icon-space-r" />
          Proposal
        </a>
      )}
      {(event.registration_status === REG_STATUS.FACILITATOR || user.isAdmin) && (
        <Link
          to={window.URLS['pd:attendance_tracker'](event.pk)}
          className="btn btn-default btn-sm icon-space-l"
        >
          <i className="fa fa-list-alt icon-space-r" />
          Attendance
        </Link>
      )}
      {(event.registration_status === REG_STATUS.FACILITATOR || user.isAdmin || user.isManager) && (
        <Link
          to={window.URLS['pd:event_registration_list'](event.pk)}
          className="btn btn-default btn-sm icon-space-l"
        >
          <i className="fa fa-users icon-space-r" />
          Registration List
        </Link>
      )}
      {(event.registration_status === REG_STATUS.FACILITATOR ||
        event.registration_status === REG_STATUS.APPROVED) && (
        <a
          href={window.URLS['pd:class_list'](event.pk)}
          className="btn btn-default btn-sm icon-space-l"
        >
          <i className="fa fa-users icon-space-r" />
          Class List
        </a>
      )}
      {isEnded && (event.registration_status === REG_STATUS.FACILITATOR || user.isAdmin) && (
        <Link
          to={window.URLS['pd:facilitator_wrap_up_detail'](event.pk)}
          className="btn btn-default btn-sm icon-space-l"
        >
          <i className="fa fa-gift icon-space-r" />
          Wrap Up Event
        </Link>
      )}
      {isEnded &&
        event.registration_status === REG_STATUS.FACILITATOR &&
        event.facilitator_requesting_ceus &&
        event.facilitator_rid_activity_number_created && (
          <a
            href={`${window.URLS['pd:certificate_export'](
              event.pk,
              user.profile.slug
            )}?facilitator=true`}
            className="btn btn-default btn-sm icon-space-l"
          >
            <i className="fa fa-file-pdf-o icon-space-r" />
            View Certificate
          </a>
        )}
      {!didRegister && event.type === STEP_FORWARD_LEADERSHIP && (
        <a href={window.URLS['pd:sfl_my_list']()} className="btn btn-default icon-space-l">
          <i className="fa fa-plus icon-space-r" />
          Apply
        </a>
      )}
      {!didRegister && registrationOpen && !event.private && !isInCart && (
        <button className="btn btn-primary icon-space-l" onClick={onAddCartClick}>
          <i className="fa fa-plus icon-space-r" />
          Add to Cart
        </button>
      )}
      {addDropOpen && !registrationOpen && !event.private && (
        <React.Fragment>
          {event.seats_open === 0 && (
            <i className="fa fa-lg icon-space-r fa-info-circle" data-tip="No seats available." />
          )}
          <button
            className="btn btn-primary icon-space-l"
            disabled={event.seats_open === 0}
            onClick={onRegistrationRequestClick}
          >
            <i className="fa fa-plus icon-space-r" />
            Register
          </button>
        </React.Fragment>
      )}
      {isInCart && (
        <a
          href={window.URLS['accounts:cart_checkout']()}
          className="btn btn-info icon-space-l"
          style={{ color: '#fff' }}
        >
          <i className="fa fa-shopping-cart icon-space-r" />
          View Cart
        </a>
      )}
      {isInCart && (
        <button className="btn btn-danger icon-space-l" onClick={onRemoveCartClick}>
          <i className="fa fa-times icon-space-r" />
          Remove From Cart
        </button>
      )}
    </>
  );
};

UserEventActions.propTypes = {
  event: eventExplorePropTypes.isRequired,
  user: userPropTypes.isRequired,
  registrationOpen: PropTypes.bool.isRequired,
  addDropOpen: PropTypes.bool.isRequired,
  onAddCartClick: PropTypes.func,
  onRemoveCartClick: PropTypes.func,
  onRegistrationRequestClick: PropTypes.func,
  showEventLink: PropTypes.bool,
};

export default UserEventActions;
