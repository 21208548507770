import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { userPropTypes } from 'helpers/proptypes';
import { RegistrationStatusLabel } from 'components';
import UserEventActions from '../events/components/UserEventActions';
import EventStatus from '../events/components/EventStatus';
import { EventDictionary } from 'helpers/dictionaries';
const { CREDIT_TYPES } = EventDictionary;

class Registration extends Component {
  static propTypes = {
    user: userPropTypes,
    reg: PropTypes.shape({
      status: PropTypes.string.isRequired,
      event: PropTypes.object.isRequired,
    }),
  };

  isRegistrationOpen() {
    const today = new Date();
    return (
      today > new Date(this.props.reg.event.registration_start) &&
      today < new Date(this.props.reg.event.registration_end)
    );
  }

  isAddDropOpen() {
    const today = new Date();
    return (
      today > new Date(this.props.reg.event.add_drop_start) &&
      today < new Date(this.props.reg.event.add_drop_end)
    );
  }

  render() {
    let creditIcons = null;
    if (this.props.reg.event.credit_approval === CREDIT_TYPES.BOTH_RID_ACET_CREDIT) {
      creditIcons = (
        <React.Fragment>
          <div className="credit-avatar">
            <a target="_blank" href="http://www.rid.org/">
              <img src="/static/images/RID.png" alt="RID Credit" className="img-responsive" />
            </a>
          </div>
          <div className="credit-avatar">
            <a target="_blank" href="http://www.rid.org/continuing-education/acet/">
              <img src="/static/images/ACET.png" alt="ACET Credit" className="img-responsive" />
            </a>
          </div>
        </React.Fragment>
      );
    } else if (this.props.reg.event.credit_approval === CREDIT_TYPES.RID_CREDIT) {
      creditIcons = (
        <React.Fragment>
          <div className="credit-avatar">
            <a target="_blank" href="http://www.rid.org/">
              <img src="/static/images/RID.png" alt="RID Credit" className="img-responsive" />
            </a>
          </div>
          <div className="credit-avatar registrations__credit-text">
            <h1 className="text-center">Only</h1>
          </div>
        </React.Fragment>
      );
    } else if (this.props.reg.event.credit_approval === CREDIT_TYPES.ACET_CREDIT) {
      creditIcons = (
        <React.Fragment>
          <div className="credit-avatar">
            <a target="_blank" href="http://www.rid.org/continuing-education/acet/">
              <img src="/static/images/ACET.png" alt="ACET Credit" className="img-responsive" />
            </a>
          </div>
          <div className="credit-avatar registrations__credit-text">
            <h1 className="text-center">Only</h1>
          </div>
        </React.Fragment>
      );
    } else if (this.props.reg.event.credit_approval === CREDIT_TYPES.NO_CREDIT) {
      creditIcons = (
        <div className="credit-avatar registrations__credit-text">
          <h1 className="text-center">No CEUs</h1>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="my-registrations">
            <div className="my-registrations__credit">
              {creditIcons}
              {!this.props.reg.event.canceled && (
                <div className="my-registrations__event-status">
                  <EventStatus
                    firstDate={moment(this.props.reg.event.first)}
                    lastDate={moment(this.props.reg.event.last)}
                  />
                </div>
              )}
            </div>
            <div className="my-registrations__info">
              <span className="publication-title">
                <img
                  className="responsive"
                  src={`/static/images/strands/${this.props.reg.event.strand_type}.png`}
                  width="32"
                  height="32"
                />{' '}
                {this.props.reg.event.title}
                <div className="my-registrations__status-label">
                  <RegistrationStatusLabel status={this.props.reg.status} />
                  {this.props.reg.event.canceled && (
                    <span className="label label-danger icon-space-l">
                      <i className="fa fa-times icon-space-r" />
                      Event Canceled
                    </span>
                  )}
                </div>
              </span>
              {this.props.reg.event.times.length ? (
                this.props.reg.event.times.map((time, index) => {
                  return (
                    <div key={index}>
                      <strong className="info-title">
                        Meeting Schedule
                        {this.props.reg.event.times.length > 1 ? ' ' + (index + 1) : ''}
                      </strong>
                      <div className="event-block__line">
                        <span className="event-block__line-icon fa fa-clock-o fa-fw" />
                        {time[0] ? time[0] : 'TBD'}
                      </div>
                      <div className="event-block__line">
                        <span className="event-block__line-icon fa fa-map-marker fa-fw" />
                        {time[2] ? time[2] : 'TBD'}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <strong className="info-title">Meeting Schedule</strong>
                  <div className="event-block__line">
                    <span className="event-block__line-icon fa fa-clock-o fa-fw" />
                    TBD
                  </div>
                  <div className="event-block__line">
                    <span className="event-block__line-icon fa fa-map-marker fa-fw" />
                    TBD
                  </div>
                </div>
              )}
              {this.props.reg.event.canceled ? (
                <React.Fragment>
                  <strong>Cancelation Reason:</strong>
                  <p>{this.props.reg.event.cancelation_reason}</p>
                </React.Fragment>
              ) : (
                <UserEventActions
                  event={this.props.reg.event}
                  user={this.props.user}
                  registrationOpen={this.isRegistrationOpen()}
                  addDropOpen={this.isAddDropOpen()}
                  showEventLink
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Registration;
