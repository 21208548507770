import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const formatDate = (date) => date.format('MMM D, YYYY');

export default class EventStatus extends React.Component {
  static propTypes = {
    firstDate: PropTypes.object.isRequired, // NOTE: should be a moment() object
    lastDate: PropTypes.object.isRequired, // NOTE: should be a moment() object
  };

  render() {
    const { firstDate, lastDate } = this.props;
    const now = moment();
    let status, date, className;
    if (firstDate.isBefore(now) && lastDate.isAfter(now)) {
      status = 'In Session';
      date = `Ends ${formatDate(lastDate)}`;
    } else if (lastDate.isBefore(now)) {
      status = 'Ended';
      date = formatDate(lastDate);
      className = 'text-danger';
    } else if (firstDate.isAfter(now)) {
      status = 'Starts';
      date = formatDate(firstDate);
      className = 'text-info';
    } else {
      return null;
    }

    return (
      <span className={className}>
        <strong className={className}>{status}</strong> {date}
      </span>
    );
  }
}
