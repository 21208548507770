import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'components';
import { failedAssertion } from 'helpers/reporting';

class ErrorPage extends React.Component {
  static propTypes = {
    error: PropTypes.object.isRequired,
    onReloadClick: PropTypes.func,
    rollbarMessage: PropTypes.string,
  };

  render() {
    failedAssertion(
      this.props.rollbarMessage || this.props.error.message || this.props.error.status
    );
    let reloadButton = null;
    if (this.props.onReloadClick) {
      reloadButton = (
        <button
          className="btn btn-outline-default pull-right"
          style={{ lineHeight: '1' }}
          onClick={this.props.onReloadClick}
        >
          <i className="fa fa-refresh icon-space-r" />
          Reload
        </button>
      );
    }
    return (
      <Alert type="danger" icon="fa-exclamation-triangle">
        {this.props.error.status}
        {reloadButton}
      </Alert>
    );
  }
}

export default ErrorPage;
