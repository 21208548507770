import React from 'react';
import PropTypes from 'prop-types';

const EmptyComponent = ({ icon, title, subtitle, button }) => (
  <div className="page-empty">
    {icon && (
      <div className="page-empty__icon">
        <span className={`fa ${icon}`} />
      </div>
    )}
    {title && <div className="page-empty__title">{title}</div>}
    {subtitle && <div className="page-empty__subtitle">{subtitle}</div>}
    {button}
  </div>
);

EmptyComponent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  button: PropTypes.element,
};

export default EmptyComponent;
