import React from 'react';
import PropTypes from 'prop-types';

import memoize from 'lodash/memoize';
import Select, { Option } from 'rc-select';
import 'rc-select/assets/index.css';

import formatAcademicYear from 'helpers/format-academic-year';

const dropdownMenuStyle = {
  maxHeight: 200,
  overflow: 'auto',
};

const baseYear = 2016;

const createOptions = (baseYear, maxYear) => {
  let years = [];
  let year = baseYear;
  while (year < maxYear) {
    years.push(formatAcademicYear(year));
    year++;
  }
  const children = years.map((year) => (
    <Option key={year} lowercaseName={year}>
      {year}
    </Option>
  ));
  return children;
};

const memoizedCreateOptions = memoize(createOptions);

const AcademicYearSelect = (props) => {
  const maxYear = new Date().getFullYear() + 2;
  const children = memoizedCreateOptions(baseYear, maxYear);
  return (
    <Select
      value={props.value}
      dropdownMenuStyle={props.dropdownMenuStyle || dropdownMenuStyle}
      style={props.style || { width: 500 }}
      optionFilterProp="lowercaseName"
      optionLabelProp="children"
      placeholder="Academic year..."
      notFoundContent="Invalid year"
      onChange={props.onChange}
    >
      {children}
    </Select>
  );
};

AcademicYearSelect.propTypes = {
  value: PropTypes.string,
  dropdownMenuStyle: PropTypes.object,
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default AcademicYearSelect;
