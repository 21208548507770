import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap-modal';

const MessageModal = (props) => {
  return (
    <Modal
      show={props.modalOpen}
      onHide={props.closeModal}
      aria-labelledby={props.modalId}
      modalPrefix="bootstrap-modal modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id={props.modalId}>{props.modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="message" className="label-control">
            {props.inputLabel}
          </label>
          <textarea
            id="message"
            name="message"
            placeholder={props.inputPlaceholder}
            onChange={props.onInputChange}
            className="textarea form-control"
            value={props.inputValue}
          />
          <p className="help-block">{props.helpBlock}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className={`btn ${props.submitBtnClasses}`} onClick={props.closeAndSubmit}>
          {props.submitBtnText}
        </button>
        <Modal.Dismiss className="btn btn-default">Cancel</Modal.Dismiss>
      </Modal.Footer>
    </Modal>
  );
};

MessageModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  closeAndSubmit: PropTypes.func.isRequired,
  modalId: PropTypes.string,
  modalTitle: PropTypes.string.isRequired,
  inputLabel: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  inputValue: PropTypes.string,
  helpBlock: PropTypes.string,
  submitBtnText: PropTypes.string,
  submitBtnClasses: PropTypes.string,
};

MessageModal.defaultProps = {
  modalId: 'MessageModal',
  inputLabel: 'Message',
  inputPlaceholder: 'Message...',
  inputValue: '',
  helpBlock: 'Optional, you may leave this blank.',
  submitBtnText: 'Submit',
  submitBtnClasses: 'btn-info',
};

export default MessageModal;
